import * as z from 'zod'

const firestoreDate = z.preprocess(val => {
  // if you create a new doc with serverTimestamp() it will be null for a split second
  if (val == null) {
    return new Date(0)
  }

  if (val instanceof Date) {
    return val
  }

  const parsed = z.object({ seconds: z.number().nonnegative() }).safeParse(val)

  if (!parsed.success) {
    return null
  }

  return new Date(parsed.data.seconds * 1000)
}, z.date())

export const optionalString = z.preprocess(val => ((z.string().nullable().optional().parse(val) ?? '').trim().length < 1 ? undefined : val), z.string().min(1).optional())

export const nullableString = z.preprocess(val => ((z.string().nullable().optional().parse(val) ?? '').trim().length < 1 ? null : val), z.string().min(1).nullable())

export const optionalBoolean = z.preprocess(val => {
  if (val === 'true' || val === true) {
    return true
  }

  if (val == null) {
    return undefined
  }

  return false
}, z.boolean().optional())

export const StringArrayValidator = z.preprocess(val => {
  if (typeof val === 'string') {
    return val.trim() === '' ? undefined : val.split(',')
  }

  return val
}, z.array(z.string()).optional())

const TimestampValidator = z.object({
  seconds: z.number().int(),
  nanoseconds: z.number().int(),
})

export const LocaleEnumValidator = z.enum(['en-US', 'de-DE'])

export const LocaleValidator = z.preprocess((lang: unknown) => {
  if (typeof lang !== 'string') {
    throw new Error('Language is not a string')
  }

  switch (lang.split('-')[0]) {
    case 'de':
      return 'de-DE'
    default:
      return 'en-US'
  }
}, LocaleEnumValidator)

export type Locale = z.infer<typeof LocaleValidator>

export const EmailContentComponentTypes = ['p', 'button', 'img', 'h1', 'h2', 'html', 'spacer', 'section', 'hr', 'content-placeholder', 'product'] as const
export type EmailContentComponentType = (typeof EmailContentComponentTypes)[number]

export const normalizeEmail = (email: string) =>
  email
    .toLowerCase()
    .trim()
    .replace(/\p{Cc}/gu, '') // remove control characters
    .normalize()

const isValidZodLiteralUnion = <T extends z.ZodLiteral<unknown>>(literals: T[]): literals is [T, T, ...T[]] => literals.length >= 2

const constructZodLiteralUnionType = <T extends z.ZodLiteral<unknown>>(literals: T[]): z.ZodUnion<[T, T, ...T[]]> => {
  if (!isValidZodLiteralUnion(literals)) {
    throw new Error('Literals passed do not meet the criteria for constructing a union schema, the minimum length is 2')
  }

  return z.union(literals)
}

const SelectedComponentIndexValidator = z.object({
  row: z.number().int().nonnegative(),
  col: z.number().int().nonnegative(),
})

export type SelectedComponentIndex = z.infer<typeof SelectedComponentIndexValidator>

export const SelectedComponentIndexPathValidator = z.array(SelectedComponentIndexValidator).nullable()

export type SelectedComponentIndexPath = z.infer<typeof SelectedComponentIndexPathValidator>

export const ProductVariantValidator = z.object({
  id: z
    .string()
    .min(1)
    .regex(/^[^-]*$/, { message: 'String must not contain "-"' }),
  title: z.string(),
  images: z.array(z.string().url()),
  price: z.number().nonnegative(),
  compareAtPrice: z.number().nonnegative().nullable(),
  url: z.string(),
})

export type ProductVariant = z.infer<typeof ProductVariantValidator>

export const ProductValidator = z.object({
  id: z
    .string()
    .min(1)
    .regex(/^[^-]*$/, { message: 'String must not contain "-"' }),
  title: z.string().min(1),
  active: z.boolean(),
  descriptionHtml: z.string(),
  variants: z.array(ProductVariantValidator),
})

export type Product = z.infer<typeof ProductValidator>

export const ProductInsertValidator = z.preprocess(val => mapUndefined(z.record(z.any()).parse(val)), ProductValidator)

export const FontStyleValidator = z.object({
  family: z.string().min(1).nullable(),
  bold: z.boolean(),
  italic: z.boolean(),
  underline: z.boolean(),
})

export type FontStyle = z.infer<typeof FontStyleValidator>

export const TextAlignValidator = z.enum(['left', 'center', 'right'])

export type TextAlign = z.infer<typeof TextAlignValidator>

const TextStyleValidator = z.object({
  color: z.string().min(1),
  fontSize: z.string().min(1),
  fontStyle: FontStyleValidator,
  textAlign: TextAlignValidator,
})

const ButtonStyleValidator = TextStyleValidator.extend({
  backgroundColor: z.string().min(1),
  align: TextAlignValidator,
  letterSpacing: z.string().min(1),
  border: z.string().min(1),
  borderRadius: z.string().min(1),
  borderColor: z.string().min(1),
  margin: z.string().min(1),
  padding: z.string().min(1),
})

const HeadingStyleValidator = TextStyleValidator.extend({
  margin: z.string().min(1),
  letterSpacing: z.string().min(1),
})

const ParagraphStyleValidator = z.object({
  color: z.string().min(1),
  fontSize: z.string().min(1),
  fontFamily: z.string().min(1).nullable(),
  lineHeight: z.string().min(1),
  backgroundColor: z.string().min(1),
  margin: z.string().min(1),
  padding: z.string().min(1),
  linkColor: z.string().min(1),
})

const SpacerStyleValidator = z.object({
  height: z.string().min(1),
})

const HorizontalRuleStyleValidator = z.object({
  color: z.string().min(1),
  height: z.string().min(1),
  padding: z.string().min(1),
})

const ImageStyleValidator = z.object({
  width: z.string().min(1),
  maxWidth: z.string().min(1),
  align: TextAlignValidator,
  margin: z.string().min(1),
})

const ProductStyleValidator = z.object({
  showPrice: z.boolean(),
  showCompareAtPrice: z.boolean(),
  maxImageWidth: z.string().min(1),
  buttonText: z.string(),
  buttonBackgroundColor: z.string().min(1).nullable(),
  buttonColor: z.string().min(1).nullable(),
  buttonFontSize: z.string().min(1).nullable(),
  buttonFontStyle: FontStyleValidator.nullable(),
  buttonPadding: z.string().min(1).nullable(),
  color: z.string().min(1).nullable(),
  backgroundColor: z.string().min(1),
  padding: z.string().min(1),
  margin: z.string().min(1),
})

export const AiImageStyleValidator = z.enum(['stockphoto', 'stockphoto-with-text', 'illustration', 'image-with-caption'])

export type AiImageStyle = z.infer<typeof AiImageStyleValidator>

export const ImageSourceValidator = z.union([
  z.string().url(),
  z.object({
    url: z.string().url().nullable(),
    prompt: z.string(),
    style: AiImageStyleValidator.optional(),
    imageUrls: z.array(z.string().url()).transform(val => [...new Set(val)]),
  }),
])

export type ImageSource = z.infer<typeof ImageSourceValidator>

export const ImageComponentValidator = z.object({
  type: z.literal('img'),
  src: ImageSourceValidator.nullable(),
  href: z.string().min(1),
  alt: z.string().min(1),
})

export type ImageComponent = z.infer<typeof ImageComponentValidator>

export const ProductComponentValidator = z.object({
  type: z.literal('product'),
  product: ProductVariantValidator.extend({
    descriptionHtml: z.string(),
  }),
})

export type ProductComponent = z.infer<typeof ProductComponentValidator>

export const ColumnLayoutValidator = z.enum(['equal', '33/67', '67/33', '25/75', '75/25', '50/25/25', '25/50/25', '25/25/50'])

export type ColumnLayout = z.infer<typeof ColumnLayoutValidator>

export const ColumnMobileBehaviorValidator = z.enum(['keep-columns', 'stack-left-to-right', 'stack-right-to-left'])

export type ColumnMobileBehavior = z.infer<typeof ColumnMobileBehaviorValidator>

const EmailContentComponentFlatValidator = z.discriminatedUnion('type', [
  ParagraphStyleValidator.partial().extend({
    type: z.literal('p'),
    text: z.string().min(1),
  }),
  ButtonStyleValidator.partial().extend({
    type: z.literal('button'),
    text: z.string().min(1),
    url: z.string().min(1),
  }),
  ImageStyleValidator.partial().merge(ImageComponentValidator),
  HeadingStyleValidator.partial().extend({
    type: z.literal('h1'),
    text: z.string().min(1),
  }),
  HeadingStyleValidator.partial().extend({
    type: z.literal('h2'),
    text: z.string().min(1),
  }),
  z.object({
    type: z.literal('html'),
    html: z.string(),
  }),
  SpacerStyleValidator.partial().extend({
    type: z.literal('spacer'),
  }),
  HorizontalRuleStyleValidator.partial().extend({
    type: z.literal('hr'),
  }),
  z.object({
    type: z.literal('content-placeholder'),
  }),
  ProductStyleValidator.partial().merge(ProductComponentValidator),
])

export type EmailContentComponentFlat = z.infer<typeof EmailContentComponentFlatValidator>

export interface EmailContentSectionCol {
  rows: EmailContentComponent[]
}

export type EmailContentSectionCols = EmailContentSectionCol[]

export type EmailContentComponent =
  | EmailContentComponentFlat
  | {
      type: 'section'
      backgroundImage?: ImageSource | null
      backgroundColor?: string | null
      margin?: string
      padding?: string
      layout?: ColumnLayout | null
      mobileBehavior?: ColumnMobileBehavior | null
      cols: EmailContentSectionCols
    }

export const SectionStyleValidator = z.object({
  backgroundColor: z.string().min(1).nullable(),
  backgroundImage: ImageSourceValidator.nullable(),
  margin: z.string().min(1),
  padding: z.string().min(1),
  layout: ColumnLayoutValidator.nullable(),
  mobileBehavior: ColumnMobileBehaviorValidator.nullable(),
})

export const EmailContentComponentValidator: z.ZodType<EmailContentComponent> = z.union([
  EmailContentComponentFlatValidator,
  SectionStyleValidator.partial().extend({
    type: z.literal('section'),
    cols: z.array(
      z.object({
        rows: z.array(z.lazy(() => EmailContentComponentValidator)),
      }),
    ),
  }),
])

export const EmailContentBodyValidator = z.array(EmailContentComponentValidator)

export type EmailContentBody = z.infer<typeof EmailContentBodyValidator>

export const EmailContentValidator = z.object({
  subject: z.string().min(1),
  previewText: z.string().min(1),
  body: EmailContentBodyValidator,
})

export const ModelValidator = z.enum(['gpt-4-turbo', 'claude-3-opus-20240229', 'claude-3-5-sonnet-20240620', 'gpt-4o', 'models/gemini-1.5-pro-latest'])

export type Model = z.infer<typeof ModelValidator>

export const EmailSendingSystemValidator = z.enum(['Klaviyo', 'SendGrid'])

const PromptValidator = z.object({
  systemPrompt: z.string().min(1),
  userPrompt: z.string().min(1),
  model: z.union([ModelValidator, z.literal('gpt-4-turbo-preview')]),
})

export type EmailContent = z.infer<typeof EmailContentValidator>

// check if EmailContentComponentValidator uses the same literals as EmailContentComponent
type ExtractType<T> = T extends { type: infer U } ? U : never
type EmailContentComponentTypeFromValidator = ExtractType<z.infer<typeof EmailContentComponentValidator>>
type Equals<T, U> = [T] extends [U] ? ([U] extends [T] ? true : false) : false
type AssertTrue<T extends true> = T
export type _TestEquality = AssertTrue<Equals<EmailContentComponentType, EmailContentComponentTypeFromValidator>>

const HeadingDefaultStyle = {
  color: '#000000',
  fontSize: '20px',
  fontStyle: {
    family: null,
    bold: false,
    italic: false,
    underline: false,
  },
  textAlign: 'left',
  letterSpacing: 'normal',
  margin: '0px',
} satisfies z.infer<typeof HeadingStyleValidator>

export const EmailComponentStyleValidator = z.object({
  h1: z.preprocess(
    val =>
      ({
        ...HeadingDefaultStyle,
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof HeadingStyleValidator>,
    HeadingStyleValidator,
  ),
  h2: z.preprocess(
    val =>
      ({
        ...HeadingDefaultStyle,
        fontSize: '18px',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof HeadingStyleValidator>,
    HeadingStyleValidator,
  ),
  p: z.preprocess(
    val =>
      ({
        color: '#000000',
        fontSize: '15px',
        fontFamily: null,
        backgroundColor: '#fffffff00',
        margin: '0px',
        padding: '0px',
        lineHeight: '1.2',
        linkColor: '#0000ee',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof ParagraphStyleValidator>,
    ParagraphStyleValidator,
  ),
  img: z.preprocess(
    val =>
      ({
        width: '100%',
        maxWidth: 'none',
        align: 'center',
        margin: '0px',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof ImageStyleValidator>,
    ImageStyleValidator,
  ),
  html: z.object({}).default({}),
  'content-placeholder': z.object({}).default({}),
  section: z.preprocess(
    val =>
      ({
        backgroundColor: null,
        backgroundImage: null,
        margin: '0px',
        padding: '0px',
        layout: 'equal',
        mobileBehavior: 'stack-left-to-right',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof SectionStyleValidator>,
    SectionStyleValidator,
  ),
  hr: z.preprocess(
    val =>
      ({
        color: '#000000',
        height: '1px',
        padding: '0px',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof HorizontalRuleStyleValidator>,
    HorizontalRuleStyleValidator,
  ),
  spacer: z.preprocess(
    val =>
      ({
        height: '20px',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof SpacerStyleValidator>,
    SpacerStyleValidator,
  ),
  button: z.preprocess(
    val =>
      ({
        color: '#ffffff',
        backgroundColor: '#000000',
        fontSize: '15px',
        fontStyle: {
          family: null,
          bold: true,
          italic: false,
          underline: false,
        },
        textAlign: 'center',
        letterSpacing: 'normal',
        align: 'center',
        border: '0px',
        borderRadius: '0px',
        borderColor: '#000000',
        margin: '0px',
        padding: '10px',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof ButtonStyleValidator>,
    ButtonStyleValidator,
  ),
  product: z.preprocess(
    val =>
      ({
        showPrice: true,
        showCompareAtPrice: false,
        maxImageWidth: '200px',
        buttonText: 'Jetzt kaufen',
        buttonBackgroundColor: null,
        buttonColor: null,
        buttonFontSize: null,
        buttonFontStyle: null,
        buttonPadding: null,
        color: null,
        backgroundColor: '#00000000',
        padding: '0px',
        margin: '0px',
        ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
      }) satisfies z.infer<typeof ProductStyleValidator>,
    ProductStyleValidator,
  ),
})

export type EmailComponentStyle = z.infer<typeof EmailComponentStyleValidator>

export const EmailStyleValidator = z.object({
  email: z.preprocess(
    val => ({
      width: '600px',
      backgroundColor: '#ffffff',
      frameColor: '#f7f7f7',
      fontFamily: 'Arial',
      ...(val == null ? {} : z.record(z.string(), z.any()).parse(val)),
    }),
    z.object({
      width: z.string().min(1),
      backgroundColor: z.string().min(1),
      frameColor: z.string().min(1),
      fontFamily: z.string().min(1),
    }),
  ),
  components: EmailComponentStyleValidator.default({}),
})

export type EmailStyle = z.infer<typeof EmailStyleValidator>

export const EmailKinds = ['content', 'promotion', 'launch', 'from_website'] as const

export const EmailKindValidator = z.enum(EmailKinds)

export type EmailKind = z.infer<typeof EmailKindValidator>

export const EmailStatsValidator = z.object({
  recipients: z.number().int().nonnegative(),
  opens: z.number().int().nonnegative(),
  clicks: z.number().int().nonnegative(),
  revenue: z.number().nonnegative(),
  bounces: z.number().int().nonnegative(),
  mark_as_spam: z.number().int().nonnegative(),
  unsubscribes: z.number().int().nonnegative(),
  orders: z.number().int().nonnegative().default(1),
  linkClicks: z
    .array(
      z.object({
        url: z.string().url(),
        count: z.number().int().nonnegative(),
        url_offset: z.number().int().nonnegative().optional(),
      }),
    )
    .default([]),
})

export type EmailStats = z.infer<typeof EmailStatsValidator>

export const SubjectIdeaValidator = z.object({
  subject: z.string().min(1),
  previewText: z.string().min(1),
})

export type SubjectIdea = z.infer<typeof SubjectIdeaValidator>

export const EmailContentToSendValidator = z.object({
  subject: nullableString,
  body: nullableString,
})

export type EmailContentToSend = z.infer<typeof EmailContentToSendValidator>

export const EmailVariantValidator = z.object({
  baseStyle: EmailStyleValidator.default({}),

  kind: EmailKindValidator.optional(),
  topic: optionalString,
  target: z.enum(['shop', 'link', 'products']).optional(),
  link: optionalString,
  hasPromotion: optionalBoolean,
  promotion: optionalString,
  discountCode: optionalString,
  notes: optionalString,
  images: z.array(z.string().url()).optional(),
  generateAiImages: optionalBoolean.default(true),
  products: z.array(z.string()).optional(),

  generation: z.discriminatedUnion('status', [
    z.object({
      status: z.literal('finished'),
      content: EmailContentValidator,
      prompt: PromptValidator,
    }),
    z.object({
      status: z.literal('generating'),
      content: z.nullable(EmailContentValidator),
    }),
    z.object({
      status: z.literal('draft'),
    }),
    z.object({
      status: z.literal('failed'),
      prompt: PromptValidator.optional(),
      json: z.string().min(1).nullable(),
      error: z.string().min(1),
    }),
    z.object({
      status: z.literal('klaviyo_import'),
    }),
  ]),

  // content to be sent
  contentToSend: EmailContentToSendValidator.optional().nullable(),

  // content that was sent (imported from klaviyo)
  sentContent: EmailContentToSendValidator.optional(),

  subjectIdeas: z.array(SubjectIdeaValidator).default([]),
  stats: EmailStatsValidator.optional(),
})

export type EmailVariant = z.infer<typeof EmailVariantValidator>

export const EmailValidator = z.object({
  createdAt: firestoreDate,
  updatedAt: firestoreDate.optional(),
  createdBy: z.string().min(1).optional(),
  state: z.enum(['draft', 'scheduled', 'sending', 'sent', 'failed']).default('draft'),
  sendTime: firestoreDate.optional(),
  sendTo: z
    .object({
      included: z.tuple([
        z.object({
          type: z.literal('AI'),
        }),
      ]),
      excluded: z.tuple([]),
    })
    .optional(),
  generatedName: z.preprocess(val => z.string().optional().parse(val)?.slice(0, 255), z.string().min(1).max(255).optional()),
  klaviyoImported: z
    .object({
      campaignId: z.string().min(1),
      name: z.string().min(1),
    })
    .optional(),
  klaviyoCampaignId: z.string().min(1).optional(),
  stats: EmailStatsValidator.optional(),
})

export type Email = z.infer<typeof EmailValidator>

export const EmailInsertValidator = EmailValidator.extend({
  createdAt: z.any(),
  sendTime: z.union([z.date(), TimestampValidator, z.object({})]).optional(),
  createdBy: z.string().min(1),
}).strict()

export type EmailInsert = z.infer<typeof EmailInsertValidator>

export const EmailUpdateValidator = EmailValidator.partial().omit({ createdAt: true }).strict().extend({
  // make serverTimestamp() pass
  updatedAt: z.any(),
})

export type EmailUpdate = z.infer<typeof EmailUpdateValidator>

export const mapUndefined = (obj: Record<string, unknown>): Record<string, unknown> =>
  Object.entries(obj as object)
    .filter(([_key, value]) => value !== undefined)
    .reduce((obj: Record<string, unknown>, [key, value]) => {
      const valObject = z.record(z.any()).safeParse(value)
      obj[key] = valObject.success ? mapUndefined(valObject.data) : value
      const valArray = z.array(z.unknown()).safeParse(value)
      if (valArray.success) {
        obj[key] = valArray.data.map(val => {
          const valObject = z.record(z.any()).safeParse(val)
          return valObject.success ? mapUndefined(valObject.data) : val
        })
      }
      return obj
    }, {})

export const EmailVariantUpdateValidator = EmailVariantValidator.partial().strict().extend({
  'generation.content.subject': z.string().optional(),
  'generation.content.previewText': z.string().optional(),
})

export const EmailVariantUpdateValidatorFilterUndefined = z.preprocess(val => {
  const valObject = z.record(z.any()).parse(val)

  return mapUndefined(valObject)
}, EmailVariantUpdateValidator)

export type EmailVariantUpdate = z.infer<typeof EmailVariantUpdateValidatorFilterUndefined>

export const EmailVariantForGenerationValidator = EmailVariantValidator.required({
  kind: true,
  topic: true,
  target: true,
  hasPromotion: true,
})

export type EmailVariantForGeneration = z.infer<typeof EmailVariantForGenerationValidator>

export const EditorDragObjectValidator = z.discriminatedUnion('action', [
  z.object({
    action: z.literal('MoveElement'),
    from: SelectedComponentIndexPathValidator.unwrap(),
  }),
  z.object({
    action: z.literal('InsertNew'),
    type: constructZodLiteralUnionType(EmailContentComponentTypes.map(literal => z.literal(literal))),
    defaultValue: EmailContentComponentValidator,
  }),
])

export type EditorDragObject = z.infer<typeof EditorDragObjectValidator>

export const EditorDropObjectValidator = z.object({
  dragObject: EditorDragObjectValidator,
  to: SelectedComponentIndexValidator,
})

export type EditorDropObject = z.infer<typeof EditorDropObjectValidator>

// spellchecker: disable
export const TimezoneValidator = z.enum([
  'Pacific/Midway',
  'Pacific/Niue',
  'Pacific/Pago_Pago',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Rarotonga',
  'Pacific/Tahiti',
  'America/Anchorage',
  'America/Juneau',
  'America/Nome',
  'America/Sitka',
  'America/Yakutat',
  'America/Santa_Isabel',
  'America/Los_Angeles',
  'America/Tijuana',
  'America/Vancouver',
  'America/Creston',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Hermosillo',
  'America/Phoenix',
  'America/Whitehorse',
  'America/Chihuahua',
  'America/Mazatlan',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Denver',
  'America/Edmonton',
  'America/Inuvik',
  'America/Ojinaga',
  'America/Yellowknife',
  'America/Belize',
  'America/Costa_Rica',
  'America/El_Salvador',
  'America/Guatemala',
  'America/Managua',
  'America/Tegucigalpa',
  'Pacific/Galapagos',
  'America/Chicago',
  'America/Indiana/Knox',
  'America/Indiana/Tell_City',
  'America/Matamoros',
  'America/Menominee',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Resolute',
  'America/Winnipeg',
  'America/Bahia_Banderas',
  'America/Cancun',
  'America/Merida',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Regina',
  'America/Swift_Current',
  'America/Bogota',
  'America/Cayman',
  'America/Coral_Harbour',
  'America/Eirunepe',
  'America/Guayaquil',
  'America/Jamaica',
  'America/Lima',
  'America/Panama',
  'America/Rio_Branco',
  'America/Detroit',
  'America/Havana',
  'America/Indiana/Petersburg',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Iqaluit',
  'America/Kentucky/Monticello',
  'America/Louisville',
  'America/Montreal',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Pangnirtung',
  'America/Port-au-Prince',
  'America/Thunder_Bay',
  'America/Toronto',
  'America/Indiana/Marengo',
  'America/Indiana/Vevay',
  'America/Indianapolis',
  'America/Caracas',
  'America/Asuncion',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Halifax',
  'America/Moncton',
  'America/Thule',
  'Atlantic/Bermuda',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Anguilla',
  'America/Antigua',
  'America/Aruba',
  'America/Barbados',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Curacao',
  'America/Dominica',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guyana',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lower_Princes',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Montserrat',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Santo_Domingo',
  'America/St_Barthelemy',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Tortola',
  'America/Santiago',
  'Antarctica/Palmer',
  'America/St_Johns',
  'America/Sao_Paulo',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Buenos_Aires',
  'America/Catamarca',
  'America/Cordoba',
  'America/Jujuy',
  'America/Mendoza',
  'America/Araguaina',
  'America/Belem',
  'America/Cayenne',
  'America/Fortaleza',
  'America/Maceio',
  'America/Paramaribo',
  'America/Recife',
  'America/Santarem',
  'Antarctica/Rothera',
  'Atlantic/Stanley',
  'America/Godthab',
  'America/Montevideo',
  'America/Bahia',
  'America/Noronha',
  'Atlantic/South_Georgia',
  'America/Scoresbysund',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Africa/Casablanca',
  'Africa/El_Aaiun',
  'America/Danmarkshavn',
  'Europe/Isle_of_Man',
  'Europe/Guernsey',
  'Europe/Jersey',
  'Europe/London',
  'Atlantic/Canary',
  'Atlantic/Faeroe',
  'Atlantic/Madeira',
  'Europe/Dublin',
  'Europe/Lisbon',
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Bamako',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Freetown',
  'Africa/Lome',
  'Africa/Monrovia',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Sao_Tome',
  'Atlantic/Reykjavik',
  'Atlantic/St_Helena',
  'Arctic/Longyearbyen',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Berlin',
  'Europe/Busingen',
  'Europe/Gibraltar',
  'Europe/Luxembourg',
  'Europe/Malta',
  'Europe/Monaco',
  'Europe/Oslo',
  'Europe/Rome',
  'Europe/San_Marino',
  'Europe/Stockholm',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Zurich',
  'Europe/Belgrade',
  'Europe/Bratislava',
  'Europe/Budapest',
  'Europe/Ljubljana',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Tirane',
  'Africa/Ceuta',
  'Europe/Brussels',
  'Europe/Copenhagen',
  'Europe/Madrid',
  'Europe/Paris',
  'Europe/Sarajevo',
  'Europe/Skopje',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Africa/Algiers',
  'Africa/Bangui',
  'Africa/Brazzaville',
  'Africa/Douala',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Luanda',
  'Africa/Malabo',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Porto-Novo',
  'Africa/Tunis',
  'Africa/Windhoek',
  'Asia/Nicosia',
  'Europe/Athens',
  'Europe/Bucharest',
  'Europe/Chisinau',
  'Asia/Beirut',
  'Africa/Cairo',
  'Asia/Damascus',
  'Europe/Helsinki',
  'Europe/Kyiv',
  'Europe/Mariehamn',
  'Europe/Nicosia',
  'Europe/Riga',
  'Europe/Sofia',
  'Europe/Tallinn',
  'Europe/Uzhhorod',
  'Europe/Vilnius',
  'Europe/Zaporizhzhia',
  'Africa/Blantyre',
  'Africa/Bujumbura',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Kigali',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Europe/Istanbul',
  'Asia/Jerusalem',
  'Africa/Tripoli',
  'Asia/Amman',
  'Asia/Baghdad',
  'Europe/Kaliningrad',
  'Asia/Aden',
  'Asia/Bahrain',
  'Asia/Kuwait',
  'Asia/Qatar',
  'Asia/Riyadh',
  'Africa/Addis_Ababa',
  'Africa/Asmera',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Mogadishu',
  'Africa/Nairobi',
  'Antarctica/Syowa',
  'Indian/Antananarivo',
  'Indian/Comoro',
  'Indian/Mayotte',
  'Europe/Kirov',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Volgograd',
  'Europe/Minsk',
  'Europe/Astrakhan',
  'Europe/Samara',
  'Europe/Ulyanovsk',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Muscat',
  'Asia/Baku',
  'Indian/Mahe',
  'Indian/Mauritius',
  'Indian/Reunion',
  'Asia/Tbilisi',
  'Asia/Yerevan',
  'Asia/Kabul',
  'Antarctica/Mawson',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Dushanbe',
  'Asia/Oral',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'Indian/Kerguelen',
  'Indian/Maldives',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Kolkata',
  'Asia/Calcutta',
  'Asia/Colombo',
  'Asia/Kathmandu',
  'Antarctica/Vostok',
  'Asia/Almaty',
  'Asia/Bishkek',
  'Asia/Qyzylorda',
  'Asia/Urumqi',
  'Indian/Chagos',
  'Asia/Dhaka',
  'Asia/Thimphu',
  'Asia/Rangoon',
  'Indian/Cocos',
  'Antarctica/Davis',
  'Asia/Bangkok',
  'Asia/Hovd',
  'Asia/Jakarta',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Saigon',
  'Asia/Vientiane',
  'Indian/Christmas',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Hong_Kong',
  'Asia/Macau',
  'Asia/Shanghai',
  'Asia/Krasnoyarsk',
  'Asia/Brunei',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Singapore',
  'Antarctica/Casey',
  'Australia/Perth',
  'Asia/Taipei',
  'Asia/Choibalsan',
  'Asia/Ulaanbaatar',
  'Asia/Irkutsk',
  'Asia/Dili',
  'Asia/Jayapura',
  'Asia/Tokyo',
  'Pacific/Palau',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Australia/Adelaide',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Antarctica/DumontDUrville',
  'Pacific/Guam',
  'Pacific/Port_Moresby',
  'Pacific/Saipan',
  'Pacific/Truk',
  'Australia/Currie',
  'Australia/Hobart',
  'Asia/Chita',
  'Asia/Khandyga',
  'Asia/Yakutsk',
  'Antarctica/Macquarie',
  'Pacific/Efate',
  'Pacific/Guadalcanal',
  'Pacific/Kosrae',
  'Pacific/Noumea',
  'Pacific/Ponape',
  'Asia/Sakhalin',
  'Asia/Ust-Nera',
  'Asia/Vladivostok',
  'Antarctica/McMurdo',
  'Pacific/Auckland',
  'Pacific/Funafuti',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Nauru',
  'Pacific/Tarawa',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Fiji',
  'Asia/Anadyr',
  'Asia/Kamchatka',
  'Asia/Magadan',
  'Asia/Srednekolymsk',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Tongatapu',
  'Pacific/Apia',
])
// spellchecker: enable

export type TimezoneName = z.infer<typeof TimezoneValidator>

export const ShopValidator = z.object({
  name: z.string().min(1),
})

export const ShopWithOwnersValidator = ShopValidator.extend({
  owners: z.array(z.string().min(1)),
})

export type ShopWithOwners = z.infer<typeof ShopWithOwnersValidator>

export const ShopWithOwnersUpdateValidator = ShopWithOwnersValidator.partial()

export type ShopWithOwnersUpdate = z.infer<typeof ShopWithOwnersUpdateValidator>

export const ShopInsertValidator = ShopWithOwnersValidator

export type ShopInsert = z.infer<typeof ShopInsertValidator>

export const ShopStateValidator = z
  .object({
    status: z.enum(['initial-sync', 'continuous-sync']).default('initial-sync'),
    inactive: z.boolean().default(false),
    lastAttemptedSyncAt: firestoreDate.optional(),
    lastSuccessfulSyncAt: firestoreDate.optional(),
    error: z.string().min(1).optional(),
  })
  .default({})

export type ShopState = z.infer<typeof ShopStateValidator>

export const ShopWithStatesValidator = ShopValidator.extend({
  syncState: z
    .object({
      klaviyoEvents: ShopStateValidator,
      klaviyoCampaigns: ShopStateValidator,
      klaviyoCampaignMessages: ShopStateValidator,
      klaviyoFlows: ShopStateValidator,
      klaviyoProfiles: ShopStateValidator,
      feedProducts: ShopStateValidator,
      fetchShopifyOrders: ShopStateValidator,
      boughtProductDescriptions: ShopStateValidator,
      profileFeatureStore: ShopStateValidator,
      sendCampaignsPreconditionsCheck: ShopStateValidator,
    })
    .default({}),
})

export type ShopWithStates = z.infer<typeof ShopWithStatesValidator>

export const CurrencyValidator = z.enum(['EUR', 'USD'])

export type Currency = z.infer<typeof CurrencyValidator>

export const ShopWithExtraInfoValidator = ShopValidator.extend({
  prompt: z.string().default(''),
  customInstructions: optionalString,
  model: ModelValidator.default('gpt-4o'),
  emailSendingSystem: EmailSendingSystemValidator.default('Klaviyo'),
  url: z.union([z.string().url(), z.literal('')]).default(''),
  aiSegmentation: optionalBoolean,
  locale: LocaleValidator.default('de-DE'),
  currency: CurrencyValidator.default('EUR'),
  timezone: TimezoneValidator.default('Europe/Berlin'),
  flows: optionalBoolean,
})

export type ShopWithExtraInfo = z.infer<typeof ShopWithExtraInfoValidator>

export const ShopWithKlaviyoValidator = ShopWithStatesValidator.extend({
  klaviyoApiKey: z.string().default(''),
  klaviyo: z
    .object({
      senderName: z.string(),
      senderEmail: z.union([z.string().email(), z.literal('')]),
      replyToAddress: z.string().email().min(1).optional().nullable(),
      defaultIncludeList: z.string().min(1).optional().nullable(),
      globalUnsubscribeLink: z.string().min(1).optional().nullable(),
      sendCampaignConfig: z
        .object({
          flowName: z.string().min(3).optional().nullable(),
          triggerMetricName: z.string().min(3).optional().nullable(),
        })
        .optional(),
    })
    .default({
      senderName: '',
      senderEmail: '',
    }),
  productsSource: z.preprocess(val => (val === 'null' ? null : val), z.enum(['shopify', 'klaviyo-feed']).optional().nullable()),
  shopifyUrl: z.string().min(1).optional().nullable(),
  shopifyApiKey: z.string().min(1).optional().nullable(),
  feedUrl: z.string().url().optional().nullable(),
})

export type ShopWithKlaviyo = z.infer<typeof ShopWithKlaviyoValidator>

export const ShopPreferencesValidator = ShopWithExtraInfoValidator.merge(ShopWithKlaviyoValidator)

export type ShopPreferences = z.infer<typeof ShopPreferencesValidator>

export const ShopUpdateValidator = z.record(z.string().min(1), z.union([z.array(z.string()), nullableString]))

export const UserValidator = z.object({
  name: optionalString,
  admin: optionalBoolean,
  assistants: z.record(z.string().min(1), z.string().min(1)).optional(),
  assistantWidth: z.number().nonnegative().optional(),
  assistantCurrentIdForShop: z.record(z.string().min(1), z.string().min(1).nullable()).default({}),
})

export type User = z.infer<typeof UserValidator>

export const UserUpdateValidator = UserValidator.partial().strict()

export type UserUpdate = z.infer<typeof UserUpdateValidator>

export const TemplateValidator = z.object({
  baseStyle: EmailStyleValidator.default({}),
  body: EmailContentBodyValidator,
})

export type Template = z.infer<typeof TemplateValidator>

export const TemplateUpdateValidator = z.preprocess(val => {
  const valObject = z.record(z.any()).parse(val)

  if (Object.values(valObject).includes(undefined)) {
    throw new Error('undefined value on level 1')
  }

  return mapUndefined(valObject)
}, TemplateValidator.partial().strict())

export type TemplateUpdate = z.infer<typeof TemplateUpdateValidator>

export const WEBSAFE_FONTNAMES = ['Arial', 'Arial Black', 'Century Gothic', 'Comic Sans MS', 'Courier', 'Courier New', 'Georgia', 'Geneva', 'Helvetica', 'Lucida', 'Lucida Grande', 'Lucida Sans', 'MS Serif', 'New York', 'Palatino', 'Palatino Linotype', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana'] as const

export const WebsafeFontValidator = z.enum(WEBSAFE_FONTNAMES)

export type WebsafeFont = z.infer<typeof WebsafeFontValidator>

export const WebfontValidator = z.object({
  name: z.string().min(1),
  variants: z
    .array(
      z.object({
        style: z.enum(['normal', 'italic']),
        weight: z.number().gte(100).lte(900),
        url: z.string().url().regex(/woff/i),
      }),
    )
    .default([]),
  fallback: WebsafeFontValidator,
})

export type Webfont = z.infer<typeof WebfontValidator>

export const WebfontUpdateValidator = WebfontValidator.partial().strict()

export type WebfontUpdate = z.infer<typeof WebfontUpdateValidator>

export const ImageCacheItemValidator = z.object({
  originalUrl: z.string().url(),
  cdnUrl: z.string().url(),
})

export type ImageCacheItem = z.infer<typeof ImageCacheItemValidator>

export const AssistantThreadValidator = z.object({
  createdAt: firestoreDate,
  assistantId: z.string().min(1),
  shopId: z.string().min(1),
  locale: LocaleValidator.default('de-DE'),
  generatedName: z.string().min(1).optional(),
  state: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('idle'),
    }),
    z.object({
      type: z.literal('generating'),
    }),
    z.object({
      type: z.literal('using_tool'),
      tool: z.enum(['function', 'code_interpreter', 'file_search']),
    }),
    z.object({
      type: z.literal('failed'),
      error: z.string().min(1),
    }),
  ]),
})

export type AssistantThread = z.infer<typeof AssistantThreadValidator>

export const AssistantThreadCreateValidator = AssistantThreadValidator.extend({
  createdAt: TimestampValidator,
}).strict()

export type AssistantThreadCreate = z.infer<typeof AssistantThreadCreateValidator>

export const AssistantThreadUpdateValidator = AssistantThreadValidator.omit({ createdAt: true }).partial().strict()

export type AssistantThreadUpdate = z.infer<typeof AssistantThreadUpdateValidator>

export const AssistantMessageValidator = z.object({
  createdAt: firestoreDate,
  role: z.enum(['user', 'assistant']),
  content: z.array(
    z.discriminatedUnion('type', [
      z.object({
        type: z.literal('text'),
        text: z.string(),
      }),
      z.object({
        type: z.literal('image'),
        url: z.string().url(),
      }),
    ]),
  ),
})

export type AssistantMessage = z.infer<typeof AssistantMessageValidator>

export const AssistantMessageCreateValidator = AssistantMessageValidator.extend({
  createdAt: TimestampValidator,
}).strict()

export type AssistantMessageCreate = z.infer<typeof AssistantMessageCreateValidator>

export const AssistantMessageUpdateValidator = AssistantMessageValidator.partial().strict()

export type AssistantMessageUpdate = z.infer<typeof AssistantMessageUpdateValidator>

export const FlowConditionValidator = z.object({
  or: z.array(
    z.discriminatedUnion('conditionType', [
      z.object({
        conditionType: z.literal('event'),
        eventType: z.string().min(1),
      }),
      z.object({
        conditionType: z.literal('property'),
        property: z.string().min(1),
        comparison: z.enum(['is', 'is_not', 'contains', 'does_not_contain']),
        value: z.string().min(1),
      }),
    ]),
  ),
})

export type FlowCondition = z.infer<typeof FlowConditionValidator>

// /shops/{shopId}/flows/{id}
export const FlowValidator = z.object({
  createdAt: firestoreDate,
  createdBy: z.string().min(1),
  name: z.string().min(1),
  trigger: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('event'),
      eventType: z.string().min(1),
    }),
  ]),
  headNodeId: z.string().min(1).nullable(),
  stopFlowIf: FlowConditionValidator,
  stats: z
    .object({
      revenue: z.number().nonnegative(),
    })
    .optional(),
})

export type Flow = z.infer<typeof FlowValidator>

export const FlowInsertValidator = FlowValidator.extend({
  createdAt: z.any(),
}).strict()

export type FlowInsert = z.infer<typeof FlowInsertValidator>

// /shops/{shopId}/flows/{id}/nodes/{id}
export const FlowNodeValidator = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('email'),
    name: z.string().min(1),
    nextNodeId: z.string().min(1).nullable(),
    stats: EmailStatsValidator.optional().nullable(),
  }),
  z.object({
    type: z.literal('condition'),
    condition: FlowConditionValidator,
    nextNodeId: z.object({
      conditionMetNodeId: z.string().min(1).nullable(),
      conditionNotMetNodeId: z.string().min(1).nullable(),
    }),
  }),
  z.object({
    type: z.literal('wait'),
    waitTimes: z.tuple([
      z.object({
        amount: z.number().int().positive(),
        unit: z.enum(['days', 'hours', 'minutes']),
      }),
    ]),
    nextNodeId: z.string().min(1).nullable(),
  }),
])

export type FlowNode = z.infer<typeof FlowNodeValidator>

export const FlowEmailNodeVariantValidator = z.object({
  createdAt: firestoreDate,
  baseStyle: EmailStyleValidator.default({}),
  subjectIdeas: z.array(SubjectIdeaValidator).default([]),
  generation: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('finished'),
      content: EmailContentValidator,
    }),
  ]),
  sendStatus: z.enum(['draft', 'live']),
  contentToSend: EmailContentToSendValidator,
  stats: EmailStatsValidator.optional().nullable(),
})

export type FlowEmailNodeVariant = z.infer<typeof FlowEmailNodeVariantValidator>

export const FlowEmailNodeVariantInsertValidator = FlowEmailNodeVariantValidator.extend({
  createdAt: TimestampValidator,
})
  .partial({ baseStyle: true, subjectIdeas: true })
  .strict()

export type FlowEmailNodeVariantInsert = z.infer<typeof FlowEmailNodeVariantInsertValidator>

export const FlowEmailNodeVariantUpdateValidator = FlowEmailNodeVariantInsertValidator.partial().strict()

export type FlowEmailNodeVariantUpdate = z.infer<typeof FlowEmailNodeVariantUpdateValidator>

// /shops/{shopId}/flows/{id}/nodes/{id}/waitingProfile/{profileId}
export const FlowNodeWaitingProfileValidator = z.object({
  waitUntil: z.object({
    date: z.date(),
    // space for conditions like weekday, ai etc.
  }),
  triggerEventData: z.unknown(),
  email: z.string().email(),
})

// /shops/{shopId}/profiles/{profileId}/events/{id}
export const EventValidator = z.object({
  type: z.string().min(1),
  eventTime: firestoreDate,
  attributes: z.unknown(),
})

export type Event = z.infer<typeof EventValidator>

export const EmailAddressValidator = z.string().min(3).includes('@')

export type EmailAddress = z.infer<typeof EmailAddressValidator>

// /shops/{shopId}/eventQueue/{id}
export const EventQueueItemValidator = EventValidator.extend({
  email: EmailAddressValidator,
  profileId: z.string().min(1),
})

export type EventQueueItem = z.infer<typeof EventQueueItemValidator>

// /shops/{shopId}/profiles/{id}
export const ProfileValidator = z.object({
  createdAt: firestoreDate,
  firstName: nullableString,
  lastName: nullableString,
  optInStatus: z.enum(['single-opt-in', 'double-opt-in', 'suppressed']),
  emails: z.tuple([EmailAddressValidator]),
})

export type Profile = z.infer<typeof ProfileValidator>

export const ProfileInsertValidator = ProfileValidator.extend({
  createdAt: TimestampValidator,
}).strict()

export type ProfileInsert = z.infer<typeof ProfileInsertValidator>

export const ProfileUpdateValidator = ProfileValidator.omit({ createdAt: true }).partial().strict()

export type ProfileUpdate = z.infer<typeof ProfileUpdateValidator>

export const ShopifyOrderValidator = z.object({
  email: z.string().nullable(),
  createdAt: firestoreDate,
  updatedAt: firestoreDate,
  cancelledAt: firestoreDate.nullable(),
  refunds: z.array(z.object({ createdAt: firestoreDate.nullable() })),
  totalPrice: z.coerce.number().nonnegative(),
  displayFulfillmentStatus: z.enum(['FULFILLED', 'IN_PROGRESS', 'ON_HOLD', 'OPEN', 'PARTIALLY_FULFILLED', 'PENDING_FULFILLMENT', 'REQUEST_DECLINED', 'RESTOCKED', 'SCHEDULED', 'UNFULFILLED']),
})

export type ShopifyOrder = z.infer<typeof ShopifyOrderValidator>

export const ShopifyOrderInsertValidator = ShopifyOrderValidator.extend({
  createdAt: z.date(),
  updatedAt: z.date(),
  cancelledAt: z.date().nullable(),
  refunds: z.array(z.object({ createdAt: z.date().nullable() })),
}).strict()

export type ShopifyOrderInsert = z.infer<typeof ShopifyOrderInsertValidator>
